import React, { FC } from "react"
import Masonry from "react-masonry-component"
import styled from "styled-components"
import { Spin, message, Image, Tooltip } from "antd"
import _ from "lodash"

import useFetchInsFeed from "../hooks/useFetchInsFeed"
import { InstagramFeed } from "../models/instagram-feed"

const GridItem = styled.section`
  width: 32%;
  margin-left: 1%;
  margin-bottom: 0.5%;
  display: block;
`
const ICImage = styled(Image)`
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
`
export default () => {
  const { loading, instagramFeeds, error, reload } = useFetchInsFeed()

  return loading ? (
    <Spin
      size="large"
      className="mx-auto"
      tip="LOADING..."
      style={{ display: "block", marginLeft: "auto" }}
    />
  ) : (
    <>
      {error ? (
        message.error(error)
      ) : (
        <InstagramFeedDisplay instagramFeeds={instagramFeeds} reload={reload} />
      )}
    </>
  )
}

const InstagramFeedDisplay: FC<{
  instagramFeeds: InstagramFeed[]
  reload: () => void
}> = ({ instagramFeeds, reload }) => {
  return (
    <Masonry
      className="w-100 mx-auto"
      options={{ transitionDuration: "3000", fitWidth: true }}
    >
      {_.sortBy(instagramFeeds, "timestamp")
        .reverse()
        .map(instaFeed => {
          return (
            <GridItem key={instaFeed.id}>
              <Tooltip placement="bottom" title={instaFeed.caption}>
                {instaFeed.media_type === "VIDEO" ? (
                  <ICImage src={instaFeed.thumbnail_url} width="100%" />
                ) : (
                  <ICImage src={instaFeed.media_url} width="100%" />
                )}
              </Tooltip>
            </GridItem>
          )
        })}
    </Masonry>
  )
}
