import { useState, useEffect, useCallback } from "react"
import { fetchYelpReviews } from "../services/ic.service"

import YelpReview from "../models/yelp-review"

export default () => {
  const [yelpReviews, setYulpReviews] = useState<YelpReview[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error>()

  const loadData = useCallback(() => {
    setLoading(true)
    fetchYelpReviews()
      .then(response => {
        setYulpReviews(response.data)
      })
      .catch(error => setError(error))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    loadData()
  }, [])

  return { loading, yelpReviews, error, reload: loadData }
}
