import React, { FC, useEffect, useState, useMemo } from "react"
import { graphql, PageProps } from "gatsby"
import Img from "gatsby-image"
import { Row, Col, Typography, Card, Divider } from "antd"
// import { Quote } from "@ant-design/icons"

import Layout from "../components/layout"
import SEO from "../components/seo"
import YelpReviews from "../components/yelp-reviews"
import InstagramFeed from "../components/instagram-feed"
import Slider, { Media } from "../components/slider"
import Booking from "../components/appointment/booking"

const { Title, Paragraph } = Typography

export const pageQuery = graphql`
  query {
    SlideOne: file(relativePath: { eq: "slider-images/slider-01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4542) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SlideTwo: file(relativePath: { eq: "slider-images/slider-02.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 4542) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SlideThree: file(relativePath: { eq: "slider-images/slider-03.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 4542) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    NailSalon: file(relativePath: { eq: "nail-salon.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 4542) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const IndexPage: FC<{}> = props => {
  const [slidesPerView, setSlidesPerView] = useState(1.1)

  const sliderImages: Array<Media> = [
    {
      type: "video",
      url: "https://www.youtube.com/embed/6gLqDRou5HM",
      ratio: 9 / 16,
    },
    {
      type: "image",
      url: props.data.SlideTwo.childImageSharp.fluid,
    },
    {
      type: "image",
      url: props.data.SlideThree.childImageSharp.fluid,
    },
  ]

  useEffect(() => {
    if (window) {
      if (window.screen.availWidth > 768) {
        setSlidesPerView(2.2)
      }
    }
  }, [])

  return (
    <Layout>
      <SEO title="Home" />

      <Slider mediaFiles={sliderImages} />

      <Row justify="center" align="middle" className="py-2 shadow-sm bg-light">
        <Col xs={24} sm={24} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
          <Title className="px-2">Little About Us</Title>
          <Title level={5} className="px-2">
            <sup>"</sup>A luxurious treat for all those parts of the body that
            work so hard for you but can often be left neglected.<sup>"</sup>{" "}
          </Title>

          <Paragraph className="px-2">
            <strong>NAILS LOUNGE by The Sea</strong> represents the ultimate
            indulgence in nail care. We exclusively use heavenly scented
            Aromatherapy, Body Oils and Lavish Scrubs to make sure your
            experience is as luxurious as can be. Performed in our Mani & Pedi
            Lounge using only the finest Nail Products, let our team beautify
            your hands and feet whilst you lap up the sumptuous scents and
            surroundings.
          </Paragraph>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={{ span: 7, offset: 1 }}
          lg={{ span: 7, offset: 1 }}
          xl={{ span: 7, offset: 1 }}
        >
          <Img fluid={props.data.NailSalon.childImageSharp.fluid}></Img>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Booking />
        </Col>
      </Row>

      <Row justify="center" align="middle" className="bg-light py-2 my-2">
        <Col span="24">
          <Title className="px-2">What People Think About Us?</Title>
          <YelpReviews slidesPerView={slidesPerView} />
        </Col>
      </Row>

      <Row justify="center" align="middle" className="py-2 my-2">
        <Col span="24">
          <Title className="px-2 text-center">Instagram</Title>
          <InstagramFeed />
        </Col>
      </Row>
    </Layout>
  )
}

export default IndexPage
