import React, { FC, useCallback, useState } from "react"
import SwiperCore, { Pagination, A11y, Autoplay, Mousewheel } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import ReactPlayer from "react-player"
import _ from "lodash"
import { Typography, Button } from "antd"
import Img, { FluidObject } from "gatsby-image"
import classnames from "classnames"

import "../styles/slider.scss"

SwiperCore.use([A11y, Autoplay, Pagination, Mousewheel])

const { Title, Paragraph } = Typography

const TextOverlay: FC<{
  justify: "left" | "right" | "center"
  alignment: "start" | "end" | "middle"
  title: string
  description: string
  actionButtonText: string
  actionLink: string
}> = ({
  justify,
  alignment,
  title,
  description,
  actionButtonText,
  actionLink,
}) => {
  return (
    <div
      className={classnames(
        "text-overlay-container rounded",
        justify,
        alignment
      )}
    >
      <div className="text-overlay-inner rounded">
        <Title level={5}>{title}</Title>
        <Paragraph>{description}</Paragraph>
        <a href={actionLink}>{actionButtonText}</a>
      </div>
    </div>
  )
}

const VideoDisplay: FC<{
  ratio: number
  url: string
  play: boolean
  title?: string
  description?: string
  justify?: "left" | "right" | "center"
  alignment?: "start" | "end" | "middle"
  actionButtonText?: string
  actionLink?: string
}> = ({
  ratio,
  url,
  play,
  title,
  description,
  justify,
  alignment,
  actionButtonText,
  actionLink,
}) => {
  const [isPlaying, setIsPlaying] = useState(play)
  const handlePlay = useCallback(() => {
    setIsPlaying(true)
  }, [])

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        padding: `${ratio * 100}% 0 0 0`,
      }}
      onClick={handlePlay}
    >
      <ReactPlayer
        title="video"
        url={url}
        frameBorder="0"
        allow="autoplay; fullscreen"
        className="bg-video"
        playing={isPlaying}
        muted
        controls
      />
    </div>
  )
}

const ImageDisplay: FC<{
  imgURL: FluidObject
  title?: string
  description?: string
  justify?: "left" | "right" | "center"
  alignment?: "start" | "end" | "middle"
  actionButtonText?: string
  actionLink?: string
}> = ({
  imgURL,
  justify,
  alignment,
  title,
  description,
  actionButtonText,
  actionLink,
}) => {
  return (
    <>
      <Img fluid={imgURL} />
      {/* <TextOverlay
        title={title}
        description={description}
        justify={justify}
        alignment={alignment}
        actionButtonText={actionButtonText}
        actionLink={actionLink}
      /> */}
    </>
  )
}

export type Media = {
  type: "video" | "image"
  url?: string
  gatsbyImage?: FluidObject
  ratio?: number
  title?: string
  description?: string
  actionButtonText?: string
  actionLink?: string
}

const Slider: FC<{ mediaFiles: Array<Media> }> = ({ mediaFiles }) => {
  return (
    <Swiper
      centeredSlides
      spaceBetween={10}
      pagination={{ type: "bullets", clickable: true }}
      mousewheel={{ forceToAxis: true }}
    >
      {mediaFiles.map(
        ({
          type,
          url,
          gatsbyImage,
          ratio,
          title,
          description,
          actionButtonText,
          actionLink,
        }) => {
          return (
            <SwiperSlide key={_.uniqueId()}>
              {({ isActive }) => {
                if (isActive) {
                  if (type === "video") {
                    return (
                      <VideoDisplay
                        ratio={ratio}
                        url={url}
                        play={isActive}
                        title={title}
                        description={description}
                      />
                    )
                  } else if (type === "image") {
                    return (
                      <ImageDisplay
                        imgURL={url}
                        title={title}
                        description={description}
                        actionButtonText={actionButtonText}
                        actionLink={actionLink}
                      />
                    )
                  }
                }
              }}
            </SwiperSlide>
          )
        }
      )}
      <br />
      <br />
    </Swiper>
  )
}

export default Slider
