import { useState, useEffect, useCallback } from "react"
import { fetchInstagramFeed } from "../services/ic.service"
import { InstagramFeed } from "../models/instagram-feed"

type FetchInstagramFeedState = {
  loading: boolean
  instagramFeeds: InstagramFeed[] | undefined
  error: Error | undefined
  reload?: () => void
}

export default (): FetchInstagramFeedState => {
  const [instagramFeeds, setInstagramFeeds] = useState<InstagramFeed[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error>()

  const loadData = useCallback(() => {
    setLoading(true)
    fetchInstagramFeed()
      .then(response => {
        setInstagramFeeds(response.data)
      })
      .catch(error => setError(error))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    loadData()
  }, [])

  return { loading, instagramFeeds, error, reload: loadData }
}
