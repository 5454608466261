import React, { FC } from "react"
import { Typography, Card, Avatar, Rate, Spin, Button, message } from "antd"
import _ from "lodash"
import SwiperCore, { Pagination, A11y, Mousewheel } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import YelpReview from "../models/yelp-review"
import useFetchYelpReviews from "../hooks/useFetchYelpReviews"

SwiperCore.use([A11y, Pagination, Mousewheel])

const { Paragraph } = Typography
const { Meta } = Card

const FetchYelpReviews: FC<{ slidesPerView: number }> = ({ slidesPerView }) => {
  const { loading, yelpReviews, error, reload } = useFetchYelpReviews()

  return loading ? (
    <Spin
      size="large"
      className="mx-auto"
      tip="LOADING..."
      style={{ display: "block", marginLeft: "auto" }}
    />
  ) : (
    <>
      {error ? (
        message.error(error)
      ) : (
        <>
          {yelpReviews?.length > 0 && (
            <Testimonials
              yelpReviews={_.filter(yelpReviews, review => review.rating > 4)}
              slidesPerView={slidesPerView}
            />
          )}
        </>
      )}
    </>
  )
}

const Testimonials: FC<{
  yelpReviews: YelpReview[]
  slidesPerView: number
}> = ({ yelpReviews, slidesPerView }) => {
  return (
    <Swiper
      slidesPerView={slidesPerView}
      spaceBetween={12}
      centeredSlides
      centeredSlidesBounds
      pagination={{
        type: "bullets",
        clickable: true,
      }}
      mousewheel={{ forceToAxis: true }}
      className="px-2"
    >
      {yelpReviews.map((review: YelpReview) => {
        return (
          <SwiperSlide key={review.id} className="my-2">
            <Card
              hoverable
              actions={[
                <Button
                  type="ghost"
                  href={review.url}
                  target="_blank"
                  key="view-on-yelp"
                  rel="noopener noreferrer"
                >
                  View On Yelp
                </Button>,
              ]}
            >
              <Meta
                avatar={<Avatar size="large" src={review.user.image_url} />}
                title={review.user.name}
                description={
                  <>
                    <Rate
                      allowHalf
                      style={{ fontSize: "12px" }}
                      value={review.rating}
                    />
                    <Paragraph>{review.text}</Paragraph>
                  </>
                }
              />
            </Card>
          </SwiperSlide>
        )
      })}
      <br />
    </Swiper>
  )
}

export default FetchYelpReviews
